function scroller() {
    document.querySelectorAll(".scroller").forEach(function (scroller) {
        scroller.addEventListener("click", function (event) {
            event.preventDefault();
            const targetId = this.getAttribute("href");
            const targetElement = document.querySelector(targetId);
            targetElement.scrollIntoView({ behavior: "smooth", block: 'start' });
        });
    });
}
export { scroller };